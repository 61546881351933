import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import type { Socket } from 'socket.io-client';
import type Barrier from 'src/models/Parking/Barrier/Barrier';
import type LotChangeEventPayload from 'src/models/Parking/Lot/SocketEvents/LotChangeEvent/LotChangeEventPayload';
import SharedProductList from 'src/screens/Parking/SharedProductList';
import useAccountService from 'src/services/Home/useAccountService';
import useAccountUserService from 'src/services/Settings/useAccountUserService';
import styled from 'styled-components';
import * as yup from 'yup';
import SocketEvents from '../../../constants/Shared/SocketEvents';
import SocketPath from '../../../constants/Shared/SocketPath';
import SocketTimer from '../../../constants/Shared/SocketTimer';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import useSocket from '../../../hooks/Shared/useSocket';
import toLotChangeEvent from '../../../mappers/Parking/Lot/toLotChangeEvent';
import type BarrierUpdateFormValues from '../../../models/Parking/Barrier/BarrierUpdateFormValues';
import useBarrierService from '../../../services/Parking/useBarrierService';
import useProductService from '../../../services/Parking/useProductService';
import { isNotNull, isNotString } from '../../../utils/checks';
import ExitButton from '../../Shared/Buttons/ExitButton';
import Form from '../../Shared/Form';
import { useGlobalConfirmationModal } from '../../Shared/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import Spinner from '../../Shared/Spinner';
import Tabs from '../../Shared/Tabs';
import BarrierSystemControlForm from '../BarrierSystemControlForm';
import BarrierUpdateForm from '../BarrierUpdateForm';

interface BarrierDetailsModalProps {
  id: number | undefined;
  productId: number | undefined;
  closeParentModal: () => void;
  onProductNameChange: (name: string, id: number, zoneId?: number) => void;
  isAnyGatewayOnline: boolean;
  hideSystemControl?: boolean;
}

const defaultFormValues: BarrierUpdateFormValues = {
  name: '',
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
});

export default function BarrierDetailsModal(props: BarrierDetailsModalProps) {
  const {
    id,
    productId,
    closeParentModal,
    onProductNameChange,
    isAnyGatewayOnline,
    hideSystemControl,
  } = props;
  const isMounted = useIsMounted();
  const { acceptAutoExtend } = useAccountService();
  const { findProfileAccountUser } = useAccountUserService();
  const { findOneBarrier, removeBarrier, updateBarrier } = useBarrierService();
  const { changeSettingsOfProduct } = useProductService();
  const [details, setDetails] = useState<Barrier | undefined>();
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [shouldRefreshPage, setShouldRefreshPage] = useState(false);
  const socket = useSocket(SocketPath.SOCKETIO);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
    refreshPage: shouldRefreshPage,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onBarrierRemove = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      await removeBarrier(id);

      if (isMounted()) {
        setMessageSuccess('Barrier deleted successfully!');
        setShouldRefreshPage(true);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    id,
    removeBarrier,
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onBarrierRemove,
    message: 'Are you sure you want to remove this barrier?',
  });

  useEffect(() => {
    const loadBarrierDetails = async () => {
      if (!id) {
        return;
      }
      try {
        if (isMounted()) {
          setAreDetailsLoading(true);
        }
        const data = await findOneBarrier(id);
        if (isMounted()) {
          setDetails(data);
          setInitialValues({ name: data.product.name });
          setAreDetailsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };
    loadBarrierDetails();
  }, [id, isMounted, findOneBarrier]);

  const onBarrierFormSubmit = useCallback(
    async (
      values: BarrierUpdateFormValues,
      { setErrors, resetForm }: FormikHelpers<BarrierUpdateFormValues>
    ) => {
      try {
        if (!id) {
          return;
        }

        await updateBarrier(id, values);

        if (isMounted()) {
          resetForm({});
          setMessageSuccess('Barrier updated successfully!');
          openGlobalSuccessModal();
          setShouldRefreshPage(false);
          onProductNameChange(
            values.name,
            productId || 0,
            details?.product.zoneId
          );
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      id,
      updateBarrier,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      onProductNameChange,
      productId,
      setMessage,
      details,
    ]
  );

  const onAutoclose = useCallback(async () => {
    try {
      if (!productId) {
        return;
      }

      const response = await changeSettingsOfProduct(productId, {
        isAutoclose: !details?.isAutoclose,
      });

      let operationListener: Socket<() => void> | undefined;

      const operationTimeout = setTimeout(() => {
        if (operationListener) {
          operationListener = socket?.off(
            SocketEvents.LOT_CHANGE,
            handleLotChange
          );
        }
        setIsInProgress(false);

        setMessage({
          code: 'Unknown',
          message: 'Operation timeout',
        });
        openGlobalFailureModal();
      }, SocketTimer.PRODUCT_OPERATION_TIMEOUT);

      if (!socket) {
        throw Error();
      }

      const handleLotChange = (payload: string) => {
        try {
          const eventPayload: LotChangeEventPayload = JSON.parse(payload);
          const event = toLotChangeEvent(eventPayload);
          const { eventId } = event;

          if (response.eventId !== eventId) return;

          clearTimeout(operationTimeout);
          operationListener = socket?.off(
            SocketEvents.LOT_CHANGE,
            handleLotChange
          );

          setDetails((oldData) => {
            if (!oldData || !event.data.barrier) {
              return oldData;
            }
            return {
              ...oldData,
              isAutoclose: event.data.barrier.isAutoclose,
            };
          });
          if (isMounted()) {
            setIsInProgress(false);
          }
        } catch {
          setMessage({ code: 'Unknown', message: 'JSON parse error' });
          openGlobalFailureModal();
        }
      };

      operationListener = socket?.on(SocketEvents.LOT_CHANGE, handleLotChange);
    } catch (error) {
      if (isMounted()) {
        setIsInProgress(false);
      }
      throw error;
    }
  }, [
    changeSettingsOfProduct,
    isMounted,
    openGlobalFailureModal,
    socket,
    setMessage,
    productId,
    details,
  ]);

  const onAcceptAutoExtend = useCallback(async () => {
    try {
      if (isMounted()) {
        setIsInProgress(true);
      }
      await acceptAutoExtend();
      onAutoclose();
    } catch (error) {
      if (isMounted()) {
        setIsInProgress(false);
      }
      throw error;
    }
  }, [acceptAutoExtend, isMounted, onAutoclose]);

  const { openGlobalConfirmationModal: openAutoExtendConfirmationModal } =
    useGlobalConfirmationModal({
      action: onAcceptAutoExtend,
      message:
        'The auto-close feature might not work with all models of vehciles and might damage your vehicle. Be sure to read the manual before using it. By clicking accept you take responsibility for any damage or harm done to vehicles using Parklio.',
      messages: { yes: 'Accept', no: 'Cancel' },
    });

  const onAutocloseToggle = useCallback(async () => {
    if (isMounted()) {
      setIsInProgress(true);
    }

    if (details?.isAutoclose) {
      onAutoclose();
      return;
    }

    const accountResponse = await findProfileAccountUser();
    const isAutoExtendEnabled = isNotNull(accountResponse.account.autoExtend);

    if (isAutoExtendEnabled) {
      onAutoclose();
    } else {
      if (isMounted()) {
        setIsInProgress(false);
      }
      openAutoExtendConfirmationModal();
    }
  }, [
    isMounted,
    findProfileAccountUser,
    details?.isAutoclose,
    onAutoclose,
    openAutoExtendConfirmationModal,
  ]);

  return (
    <>
      {isInProgress && (
        <StyledSpinner>
          <Spinner primary small />
          <StyledSpan>Processing...</StyledSpan>
        </StyledSpinner>
      )}
      <ExitButton onClick={closeParentModal} />
      <Tabs>
        <Tabs.Panel name='details' label='Details'>
          <Form
            name='barrier'
            onSubmit={onBarrierFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            isLoading={areDetailsLoading}
          >
            <BarrierUpdateForm
              details={details}
              openRemoveModal={openGlobalConfirmationModal}
              closeParentModal={closeParentModal}
              isAnyGatewayOnline={isAnyGatewayOnline}
              isLoading={areDetailsLoading}
            />
          </Form>
        </Tabs.Panel>
        {!hideSystemControl ? (
          <Tabs.Panel name='systemControl' label='System Control'>
            <BarrierSystemControlForm
              onChange={onAutocloseToggle}
              state={details?.isAutoclose}
            />
          </Tabs.Panel>
        ) : (
          <></>
        )}
        <Tabs.Panel name='sharedAccess' label='Shared Access'>
          <SharedProductList
            modalView
            productId={productId || 0}
            licensePlates
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}

const StyledSpinner = styled.div`
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  border-radius: 10px;
`;

const StyledSpan = styled.span`
  margin-left: 10px;
`;
