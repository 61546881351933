import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import SharedProductList from 'src/screens/Parking/SharedProductList';
import useChainService from 'src/services/Parking/useChainService';
import * as yup from 'yup';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import type Chain from '../../../models/Parking/Chain/Chain';
import type ChainFormValues from '../../../models/Parking/Chain/ChainFormValues';
import { isNotString } from '../../../utils/checks';
import ExitButton from '../../Shared/Buttons/ExitButton';
import Form from '../../Shared/Form';
import { useGlobalConfirmationModal } from '../../Shared/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import Tabs from '../../Shared/Tabs';
import ChainUpdateForm from '../ChainUpdateForm';

interface ChainDetailsModalProps {
  id: number | undefined;
  productId: number | undefined;
  closeParentModal: () => void;
  onProductNameChange: (name: string, id: number, zoneId?: number) => void;
  isAnyGatewayOnline: boolean;
}

export default function ChainDetailsModal(props: ChainDetailsModalProps) {
  const {
    id,
    productId,
    closeParentModal,
    onProductNameChange,
    isAnyGatewayOnline,
  } = props;
  const isMounted = useIsMounted();
  const { findOneChain, removeChain, updateChain } = useChainService();
  const defaultFormValues: ChainFormValues = {
    name: '',
  };
  // const { changeStateOfProduct } = useProductService();
  const [details, setDetails] = useState<Chain | undefined>();
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  // const [isInProgress, setIsInProgress] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  // const socket = useSocket(SocketPath.SOCKETIO);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    const loadChainDetails = async () => {
      if (!id) {
        return;
      }
      try {
        if (isMounted()) {
          setAreDetailsLoading(true);
        }

        const data = await findOneChain(id);
        if (isMounted()) {
          setDetails(data);
          setInitialValues({
            name: data.product.name,
          });
          setAreDetailsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };
    loadChainDetails();
  }, [id, isMounted, findOneChain]);

  const onChainRemove = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      await removeChain(id);

      if (isMounted()) {
        setMessageSuccess('Chain deleted successfully!');
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    id,
    removeChain,
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onChainRemove,
    message: 'Are you sure you want to remove this chain?',
  });

  const onChainFormSubmit = useCallback(
    async (
      values: ChainFormValues,
      { setErrors, resetForm }: FormikHelpers<ChainFormValues>
    ) => {
      try {
        if (!id) {
          return;
        }
        await updateChain(id, values);
        if (isMounted()) {
          resetForm({});
          setMessageSuccess('Chain updated successfully!');
          openGlobalSuccessModal();
          onProductNameChange(
            values.name,
            productId || 0,
            details?.product.zoneId
          );
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      id,
      updateChain,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      onProductNameChange,
      productId,
      setMessage,
      details,
    ]
  );

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is a required field'),
  });

  // const chainSystemControlValidationSchema = yup.object().shape({
  //     permanentOpen: yup.object().shape({
  //         key: yup.boolean().required(),
  //     }),
  // });

  // const onPermanentOpenChange = useCallback(async () => {
  //     try {
  //         if (!productId) {
  //             return;
  //         }

  //         if (isMounted()) {
  //             setIsInProgress(true);
  //         }

  //         const response = await changeStateOfProduct(productId, {
  //             operation:
  //                 details?.permanentPosition === "stay_open"
  //                     ? ProductOperations.CLEAR_STAY_CLOSE
  //                     : ProductOperations.SET_STAY_OPEN,
  //         });

  //         let operationListener:
  //             | SocketIOClient.Emitter
  //             | undefined = undefined;

  //         const operationTimeout = setTimeout(() => {
  //             if (operationListener) {
  //                 operationListener = socket?.off(SocketEvents.LOT_CHANGE,handleLotChange);
  //             }
  //             setIsInProgress(false);

  //             setMessage({
  //                 code: "Unknown",
  //                 message: "Operation timeout",
  //             });
  //             openGlobalFailureModal();
  //         }, SocketTimer.PRODUCT_OPERATION_TIMEOUT);

  //         if (!socket) {
  //             throw Error();
  //         }

  //         const handleLotChange = (payload: string) => {
  //             const eventPayload: LotChangeEventPayload = JSON.parse(payload);
  //             const event = toLotChangeEvent(eventPayload);
  //             const { eventId } = event;

  //             if (response.eventId !== eventId) return;

  //             clearTimeout(operationTimeout);
  //             operationListener = socket?.off(
  //                 SocketEvents.LOT_CHANGE,
  //                 handleLotChange
  //             );

  //             setDetails((oldData) => {
  //                 if(!oldData || !event.data.chain) {
  //                     return oldData;
  //                 }
  //                 return {...oldData, permanentPosition: event.data.chain.permanentPosition}
  //             });

  //             if (isMounted()) {
  //                 setIsInProgress(false);
  //             }
  //         };

  //         operationListener = socket?.on(SocketEvents.LOT_CHANGE, handleLotChange);

  //     } catch (error) {
  //         if (isMounted()) {
  //             setIsInProgress(false);
  //         }
  //         throw error;
  //     }
  // }, [
  //     changeStateOfProduct,
  //     productId,
  //     isMounted,
  //     openGlobalFailureModal,
  //     setMessage,
  //     socket,
  //     details
  // ]);

  return (
    <>
      {/* {isInProgress && <InProgress />} */}
      <ExitButton onClick={closeParentModal} />
      <Tabs>
        <Tabs.Panel name='details' label='Details'>
          <Form
            name='chain'
            onSubmit={onChainFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            isLoading={areDetailsLoading}
          >
            <ChainUpdateForm
              details={details}
              openRemoveModal={openGlobalConfirmationModal}
              closeParentModal={closeParentModal}
              isAnyGatewayOnline={isAnyGatewayOnline}
              isLoading={areDetailsLoading}
            />
          </Form>
        </Tabs.Panel>
        {/* <Tabs.Panel name='systemControl' label='System Control'>
					<ChainSystemControlForm
						state={details?.permanentPosition}
						onChange={onPermanentOpenChange}
					/>
				</Tabs.Panel> */}
        <Tabs.Panel name='sharedAccess' label='Shared Access'>
          <SharedProductList modalView productId={productId || 0} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
