import { createContext } from 'react';
import type Lot from 'src/models/Parking/Lot/Lot';

interface LotsContextData {
  lotData: Lot | undefined;
  setLotData: React.Dispatch<React.SetStateAction<Lot | undefined>>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const initialLotContextData: LotsContextData = {
  lotData: undefined,
  setLotData: () => {},
  isLoading: false,
  setIsLoading: (isLoading: boolean) => {},
};

const LotsContext = createContext(initialLotContextData);

export default LotsContext;
