import { useField, useFormikContext } from 'formik';
import React from 'react';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import Control from '../Shared/Control';
import CounterField from '../Shared/CounterField';
import Error from '../Shared/Error';

import Line from '../Shared/Line';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import Section from '../Shared/Section';
import Table from '../Shared/Table';
import Title from '../Shared/Title';

interface SendInquiryFormProps {
  closeParentModal: () => void;
}

export default function SendInquiryForm(props: SendInquiryFormProps) {
  const { closeParentModal } = props;
  const { isSubmitting, dirty } = useFormikContext();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });
  const [, meta] = useField({ name: 'at-least-one-count' });
  const { error } = meta;

  return (
    <>
      <Section>
        <Title bold>Licenses</Title>
        <Table margin customWidth>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <CounterField name='barrierCount' label='Barriers' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='bollardCount' label='Bollards' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='brainCount' label='Brains' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='chainCount' label='Chains ' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='gateCount' label='Gates' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='lprCameraCount' label='LPR cameras' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='parkingPlaceCount' label='Parking places' />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {error && <Error>*{error}</Error>}
      </Section>
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>

        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Add
        </Button>
      </Control>
    </>
  );
}
