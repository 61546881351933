import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import useLotsContext from 'src/hooks/Lots/useLotsContext';
import type QRCodeSharingFormValues from 'src/models/KeySharing/QRCodes/QRCodeSharingFormValues';
import useQRCodeService from 'src/services/KeyManagement/useQRCodesService';
import * as yup from 'yup';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import { isNotString } from '../../../utils/checks';
import Form from '../../Shared/Form';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import QRCodeDisplay from '../KeySharing/QRCodeDisplay';
import QRCodesSharingForm from '../KeySharing/QRCodesSharingForm';

interface ShareQRCodesModalProps {
  productId: number;
  closeParentModal: () => void;
  setSharableProducts: (products: number[]) => void;
}

const initialValues: QRCodeSharingFormValues = {
  endTime: null,
  startTime: null,
  isUnlimited: false,
};

const validationSchema = yup.object().shape({
  isUnlimited: yup.boolean().notRequired(),
  startTime: yup.date().nullable().required('Start Time is a required field'),
  endTime: yup
    .date()
    .nullable()
    .when('isUnlimited', {
      is: false,
      then: yup
        .date()
        .required('End Time is a required field')
        .min(yup.ref('startTime'), "End Time can't be before Start Time"),
    }),
});

export default function ShareQRCodesModal(props: ShareQRCodesModalProps) {
  const { productId, closeParentModal, setSharableProducts } = props;
  const isMounted = useIsMounted();
  const { createQRCodeImg } = useQRCodeService();
  const [qrImg, setQRImg] = useState<string>();
  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});
  const [timezone, setTimezone] = useState<number>(
    new Date().getTimezoneOffset() / -60
  );
  const { lotData } = useLotsContext();

  useEffect(() => {
    if (!lotData) {
      return;
    }
    setTimezone(lotData.timeZone);
  }, [isMounted, lotData]);

  const onSubmit = useCallback(
    async (
      values: QRCodeSharingFormValues,
      { setErrors, resetForm }: FormikHelpers<QRCodeSharingFormValues>
    ) => {
      try {
        if (!productId) {
          return;
        }
        const response = await createQRCodeImg(productId, timezone, values);
        if (isMounted()) {
          resetForm({});
          setQRImg(response);
          setSharableProducts([]);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      productId,
      openGlobalFailureModal,
      setSharableProducts,
      setMessage,
      timezone,
      createQRCodeImg,
    ]
  );
  return (
    <>
      {qrImg ? (
        <>
          <QRCodeDisplay qrImg={qrImg} closeParentModal={closeParentModal} />
        </>
      ) : (
        <Form
          name='ShareQRCode'
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <QRCodesSharingForm closeParentModal={closeParentModal} />
        </Form>
      )}
    </>
  );
}
