import React from 'react';
import Button from 'src/components/Shared/Button';
import Section from 'src/components/Shared/Section';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import styled from 'styled-components';
interface ShareQRCodesModalProps {
  qrImg: string;
  closeParentModal: () => void;
}
export default function QRCodeDisplay(
  QRCodeDisplayProps: ShareQRCodesModalProps
) {
  const { qrImg, closeParentModal } = QRCodeDisplayProps;
  return (
    <Section>
      <img src={qrImg} />
      <StyledWrapper>
        <Button
          onClick={closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
        >
          Close
        </Button>
        <a href={qrImg} download>
          <Button size={ButtonSize.LARGE} type='button' primary>
            Download
          </Button>
        </a>
      </StyledWrapper>
    </Section>
  );
}
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;
