import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalModalProvider } from './components/Shared/GlobalModal';
import AuthContext from './contexts/Auth/AuthContext';
import LotsContext from './contexts/Lots/LotsContext';
import type Lot from './models/Parking/Lot/Lot';
import Routes from './routes';
import useSessionService from './services/Shared/useSessionService';

export default function App() {
  const { getSessionData } = useSessionService();
  const [initialIsLoggedInState, initialSessionData] = useMemo(() => {
    const sessionData = getSessionData();

    if (!sessionData) {
      return [false, undefined];
    }

    return [true, sessionData];
  }, [getSessionData]);
  const [isLoggedIn, setIsLoggedIn] = useState(initialIsLoggedInState);
  const [sessionData, setSessionData] = useState(initialSessionData);

  const [lotData, setLotData] = useState<Lot>();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, setIsLoggedIn, sessionData, setSessionData }}
    >
      <Router>
        <LotsContext.Provider
          value={{ lotData, setLotData, isLoading, setIsLoading }}
        >
          <GlobalModalProvider>
            <Routes />
          </GlobalModalProvider>
        </LotsContext.Provider>
      </Router>
    </AuthContext.Provider>
  );
}
