import type { FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import { useGlobalFailureModal } from 'src/components/Shared/Modals/GlobalFailureModal';
import * as yup from 'yup';
import Finish from '../../components/Home/FinishStep';
import InfoStep from '../../components/Home/InfoStep';
import UserStep from '../../components/Home/UserStep';
import Wizard from '../../components/Shared/Wizard';
import AccountRole from '../../constants/Shared/AccountRole';
import PhoneValidation from '../../constants/Shared/PhoneValidation';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type AccountWizardValues from '../../models/Home/AccountWizardValues';
import useAccountService from '../../services/Home/useAccountService';
import { isNotString } from '../../utils/checks';

interface WizzardProps {
  closeParentModal: () => void;
  refreshPage?: boolean;
}

const defaultValues: AccountWizardValues = {
  name: '',
  accountRole: { key: AccountRole.PMS, label: AccountRole[2] },
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  loginEmail: '',
  loginName: '',
};

const infoValidationSchema = yup.object().shape({
  name: yup.string().required('Account is a required field'),
  accountRole: yup
    .object()
    .required('Account Type is a required field')
    .nullable(),
  contactName: yup.string().notRequired(),
  contactEmail: yup.string().notRequired().email('Enter a valid email address'),
  contactPhone: yup
    .string()
    .trim()
    .matches(PhoneValidation, 'Enter a valid phone number'),
});

const userValidationSchema = yup.object().shape({
  loginEmail: yup
    .string()
    .email('Enter a valid email address')
    .required('Email is a required field'),
  loginName: yup.string().required('Name is a required field'),
});

export default function AddAccountWizzard(props: WizzardProps) {
  const { closeParentModal, refreshPage } = props;
  const { createAccount } = useAccountService();
  const isMounted = useIsMounted();

  const {
    openGlobalFailureModal: accountFailed,
    setMessage: setAccountFailedMessage,
  } = useGlobalFailureModal({});

  const onSubmit = useCallback(
    async (
      values: AccountWizardValues,
      { setErrors }: FormikHelpers<AccountWizardValues>
    ) => {
      try {
        await createAccount(values);
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            setAccountFailedMessage({
              code: 'Unknown',
              message: `${Object.keys(error)[0]}: ${Object.values(error)[0]}`,
            });
          } else {
            setAccountFailedMessage(error);
          }
          accountFailed();
        }
        throw error;
      }
    },
    [createAccount, isMounted, accountFailed, setAccountFailedMessage]
  );

  return (
    <Wizard name='addAccount' initialValues={defaultValues} onSubmit={onSubmit}>
      <Wizard.Step
        name='info'
        label='INFO'
        validationSchema={infoValidationSchema}
      >
        <InfoStep closeParentModal={closeParentModal} />
      </Wizard.Step>
      <Wizard.Step
        name='user'
        label='USER'
        validationSchema={userValidationSchema}
      >
        <UserStep />
      </Wizard.Step>
      <Wizard.Step name='finish' label='FINISH'>
        <Finish closeParentModal={closeParentModal} refreshPage={refreshPage} />
      </Wizard.Step>
    </Wizard>
  );
}
