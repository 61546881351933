import React, { useCallback, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import SettingsButtonsTitles from 'src/constants/Shared/SettingsButtonsTitles';
import AccountMenu from '../components/Routes/AccountMenu';
import UserMenu from '../components/Routes/UserMenu';
import Container from '../components/Shared/Container';
import PrimaryNavigation from '../components/Shared/PrimaryNavigation';
import AppRole from '../constants/Shared/AppRole';
import PrimaryNavigationButtonIcon from '../constants/Shared/PrimaryNavigationButtonIcon';
import useAuthContext from '../hooks/Auth/useAuthContext';
import useUserHasRole from '../hooks/Shared/useUserHasRole';
import Auth from './Auth';
import Connect from './Connect';
import Home from './Home';
import Parking from './Parking';
import Rotaries from './Rotaries';
import Settings from './Settings/Settings';

export default function Routes() {
  const { isLoggedIn, sessionData } = useAuthContext();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const userHasRole = useUserHasRole();
  const history = useHistory();
  const redirectURL = () => {
    // when pressing back after login, if there is no history.location.state.goBackLocation it will redirect to /home, otherwise redirect to goBackLocation
    if (history.location.state)
      return Object.values(
        history.location.state as Record<string, any>
      ).toString();
    return '/home';
  };

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userCanReadAccount = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN |
      AppRole.PMS_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const openUserMenu = useCallback(() => {
    setIsUserMenuOpen(true);
  }, []);

  const closeUserMenu = useCallback(() => {
    setIsUserMenuOpen(false);
  }, []);

  const openAccountMenu = useCallback(() => {
    setIsAccountMenuOpen(true);
  }, []);

  const closeAccountMenu = useCallback(() => {
    setIsAccountMenuOpen(false);
  }, []);

  const firstletter = useMemo(() => {
    if (!sessionData) {
      return '';
    }

    return sessionData.name.slice(0, 1);
  }, [sessionData]);

  if (sessionData?.role === AppRole.NONE) {
    return <Connect />;
  }

  if (!isLoggedIn) {
    return <Auth />;
  }

  return (
    <Container>
      <PrimaryNavigation>
        <PrimaryNavigation.Navigation>
          <PrimaryNavigation.Link to='/home'>Dashboard</PrimaryNavigation.Link>
          <PrimaryNavigation.Link to='/parking'>
            Parking Lots
          </PrimaryNavigation.Link>
          {userIsParklioInternal && (
            <PrimaryNavigation.Link to='/tariffs'>
              Tariffs
            </PrimaryNavigation.Link>
          )}
          {userIsParklioInternal && (
            <PrimaryNavigation.Link to='/rotaries'>
              Rotaries
            </PrimaryNavigation.Link>
          )}
        </PrimaryNavigation.Navigation>
        <PrimaryNavigation.Actions>
          {userCanReadAccount && (
            <PrimaryNavigation.Button
              onClick={openAccountMenu}
              icon={PrimaryNavigationButtonIcon.COG}
              title={SettingsButtonsTitles.SETTINGS}
            />
          )}
          <PrimaryNavigation.Button
            onClick={openUserMenu}
            text={firstletter}
            title={SettingsButtonsTitles.PROFILE}
          />
        </PrimaryNavigation.Actions>
      </PrimaryNavigation>
      {isUserMenuOpen && <UserMenu close={closeUserMenu} />}
      {isAccountMenuOpen && <AccountMenu close={closeAccountMenu} />}
      <Switch>
        <Route exact path='/'>
          <Redirect to='/home' />
        </Route>
        <Route exact path='/login'>
          <Redirect to={redirectURL()} />
        </Route>
        <Route path='/home'>
          <Home />
        </Route>
        <Route path='/parking'>
          <Parking />
        </Route>
        {userIsParklioInternal && (
          <Route path='/rotaries'>
            <Rotaries />
          </Route>
        )}
        <Route path='/settings'>
          <Settings />
        </Route>
        <Route>
          <h3>404 PAGE</h3>
        </Route>
      </Switch>
    </Container>
  );
}
