enum ProductLicensesCountProducts {
  BARRIERS = 'Barriers',
  BOLLARDS = 'Bollards',
  BRAINS = 'Brains',
  CHAINS = 'Chains',
  GATES = 'Gates',
  LPR_CAMERAS = 'LPR Cameras',
  PARKING_PLACES = 'Parking Places',
}

const ProductLicensesLabels: Record<string, ProductLicensesCountProducts> = {
  barriers: ProductLicensesCountProducts.BARRIERS,
  bollards: ProductLicensesCountProducts.BOLLARDS,
  brains: ProductLicensesCountProducts.BRAINS,
  chains: ProductLicensesCountProducts.CHAINS,
  gates: ProductLicensesCountProducts.GATES,
  lprCameras: ProductLicensesCountProducts.LPR_CAMERAS,
  parkingPlaces: ProductLicensesCountProducts.PARKING_PLACES,
};

export { ProductLicensesCountProducts, ProductLicensesLabels };
