import type AccountProductLicenses from 'src/models/License/AccountProductLicenses';
import type AccountProductLicensesResponse from 'src/models/License/AccountProductLicensesResponse';

export default function toAccountProductLicenses(
  data: AccountProductLicensesResponse
): AccountProductLicenses {
  const {
    parking_places,
    barriers,
    brains,
    bollards,
    chains,
    lpr_cameras,
    gates,
  } = data;

  return {
    parkingPlaces: parking_places,
    barriers,
    brains,
    bollards,
    chains,
    lprCameras: lpr_cameras,
    gates,
  };
}
