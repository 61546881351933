import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ButtonsContainer from 'src/components/Shared/ButtonsContainer';
import Content from 'src/components/Shared/Content';
import { useGlobalFailureModal } from 'src/components/Shared/Modals/GlobalFailureModal';

import GlobalModal from 'src/components/Shared/Modals/GlobalModal';
import { useGlobalSuccessModal } from 'src/components/Shared/Modals/GlobalSuccessModal';

import StyledBlockIcon from 'src/components/Shared/StyledBlockIcon';
import StyledNoData from 'src/components/Shared/StyledNoData';
import Title from 'src/components/Shared/Title';

import ActionIcon from 'src/constants/Shared/ActionIcon';
import PaginationSize from 'src/constants/Shared/DataSize';

import InitialMetaData from 'src/constants/Shared/InitialMetaData';
import useLotsContext from 'src/hooks/Lots/useLotsContext';
import { useGlobalModal } from 'src/hooks/Shared/useGlobalModal';

import type QRCode from 'src/models/KeySharing/QRCode';

import type SharedListFilterValues from 'src/models/KeySharing/SharedListFilterValues';
import useQRCodeService from 'src/services/KeyManagement/useQRCodesService';

import formatOperationTokenDates from 'src/utils/formatOperationTokenDates';

import * as yup from 'yup';
import ButtonSize from '../../../constants/Shared/ButtonSize';
import PaginationItemDisplay from '../../../constants/Shared/PaginationItemDisplay';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import type Meta from '../../../models/Shared/Meta';
import Button from '../../Shared/Button';
import Card from '../../Shared/Card';
import Form from '../../Shared/Form';
import Main from '../../Shared/Main';
import Pagination from '../../Shared/Pagination';
import SearchFilter from '../../Shared/SearchFilter';
import StyledCell from '../../Shared/StyledCell';
import Table from '../../Shared/Table';
import ProductTableIcon from '../ProductTableIcon';
import QRCodeDisplay from './QRCodeDisplay';

const initialValues: SharedListFilterValues = {
  dateFrom: null,
  dateTo: null,
};

const validationSchema = yup.object().shape({
  dateFrom: yup.date().nullable().notRequired(),
  dateTo: yup
    .date()
    .nullable()
    .notRequired()
    .when('dateFrom', {
      is: (dateFrom: Date | null) => dateFrom !== null,
      then: yup
        .date()
        .nullable()
        .notRequired()
        .min(yup.ref('dateFrom'), "End Date can't be before Start Date"),
    }),
});

interface QRcodesTableProps {
  modalView?: boolean;
  productId?: number;
}

export default function QRCodesTable(props: QRcodesTableProps) {
  const { modalView, productId } = props;

  const isMounted = useIsMounted();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState<{
    [key: number]: boolean;
  }>({});
  const [showConfirmationButtons, setShowConfirmationButtons] = useState<{
    [key: number]: boolean;
  }>({});
  const [data, setData] = useState<QRCode[]>([]);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);
  const { params } = useRouteMatch<{ lotId: string }>();
  const { lotId } = params;
  const [activeQRCodeImg, setActiveQRCodeImg] = useState<string>('');
  const { getAllQRCodes, getQRCodeImg, removeQRCode } = useQRCodeService();
  const [filterValues, setFilterValues] = useState<SharedListFilterValues>({
    dateFrom: undefined,
    dateTo: undefined,
  });
  const [timezone, setTimezone] = useState<number>(
    new Date().getTimezoneOffset() / -60
  );

  const { lotData } = useLotsContext();

  const [messageSuccess, setMessageSuccess] = useState('');
  const [shouldRefreshPage, setShouldRefreshPage] = useState(false);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: messageSuccess,
    refreshPage: shouldRefreshPage,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    if (!lotData) {
      return;
    }
    setTimezone(lotData.timeZone);
  }, [isMounted, lotData]);

  const getQRCodesData = useCallback(
    async (page: number) => {
      try {
        if (!lotData?.id) return;

        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { dateFrom, dateTo } = filterValues;
        const { data, meta } = await getAllQRCodes({
          page,
          size: PaginationSize.STANDARD,
          lotId: lotId ? parseInt(lotId) : undefined,
          productId: productId,
          startTime: dateFrom?.toISOString(),
          endTime: dateTo?.toISOString(),
        });

        if (isMounted()) {
          setData(data);

          setIsDataLoading(false);
          if (meta !== undefined) {
            setMeta(meta);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [getAllQRCodes, isMounted, lotData?.id, filterValues, lotId, productId]
  );

  useEffect(() => {
    getQRCodesData(1);
  }, [getQRCodesData]);

  const onSubmit = useCallback(
    async (values: SharedListFilterValues) => {
      const { dateFrom, dateTo } = values;

      if (isMounted()) {
        setFilterValues((oldVal) => {
          const { dateFrom: oldDateFrom, dateTo: oldDateTo } = oldVal;
          if (oldDateFrom === dateFrom && oldDateTo === dateTo) {
            return oldVal;
          }
          return {
            dateFrom,
            dateTo,
          };
        });
      }
    },
    [isMounted]
  );

  const [openShareQRCode, closeShareQRCode] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <QRCodeDisplay
        qrImg={activeQRCodeImg}
        closeParentModal={onCloseQRCodeClick}
      />
    </GlobalModal>
  ));

  const onResetAllFilters = useCallback(() => {
    if (isMounted()) {
      setFilterValues((oldVal) => {
        const { dateFrom: oldDateFrom, dateTo: oldDateTo } = oldVal;
        if (!oldDateFrom && !oldDateTo) {
          return oldVal;
        }
        return {
          dateFrom: undefined,
          dateTo: undefined,
        };
      });
    }
  }, [isMounted]);

  const onDeleteIconClick = useCallback(
    (id: number) => {
      if (showDeleteIcon[id]) {
        setShowDeleteIcon({ [id]: false });
        setShowConfirmationButtons({ [id]: true });
      }
    },
    [showDeleteIcon]
  );
  const onDeleteQRCodeClick = useCallback(
    async (id: number) => {
      try {
        await removeQRCode(id);

        if (isMounted()) {
          setMessageSuccess('QR Code deleted successfully!');
          setShouldRefreshPage(true);
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      openGlobalFailureModal,
      openGlobalSuccessModal,
      removeQRCode,
      setMessage,
    ]
  );
  const onOpenQRCode = useCallback(
    async (id: number, timezone: number) => {
      try {
        if (isMounted()) {
          const qrImg = await getQRCodeImg(id, timezone);
          setActiveQRCodeImg(qrImg);
          openShareQRCode();
        }
      } catch (error: any) {
        if (isMounted()) {
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      getQRCodeImg,
      openShareQRCode,
      isMounted,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  const onCloseQRCodeClick = useCallback(async () => {
    setActiveQRCodeImg('');
    closeShareQRCode();
  }, [closeShareQRCode]);

  return (
    <Main modalView={modalView}>
      <Content widthSize='27%' modalView={modalView}>
        {!modalView && <Title>QR Codes</Title>}
        <Form
          name='Filter'
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <SearchFilter
            maxBookingDateFlag
            modalView={modalView}
            onResetAllFilters={onResetAllFilters}
          />
        </Form>
      </Content>
      <Content widthSize={modalView ? '100%' : '70%'} addTopMargin={!modalView}>
        <Card modalView={modalView}>
          <Table isLoading={isDataLoading}>
            <Table.Head>
              <Table.Row>
                <Table.Header>ID</Table.Header>
                {!modalView && <Table.Header>PRODUCT</Table.Header>}
                <Table.Header>SHARED QR Codes</Table.Header>
                <Table.Header />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {data.length > 0 ? (
                data.map((QRCodeData) => {
                  const { id, startTime, endTime, productTypeId, deviceId } =
                    QRCodeData;

                  const isExpired = new Date(endTime) < new Date();
                  const onHover = () => {
                    if (showConfirmationButtons[id] !== true) {
                      setShowDeleteIcon({ [id]: true });
                    }
                  };
                  const onLeave = () => {
                    setShowDeleteIcon({ [id]: false });
                  };

                  const onIconClick = () => {
                    onDeleteIconClick(id);
                  };

                  const onCancelClick = () => {
                    setShowConfirmationButtons({ [id]: false });
                  };

                  const onDeleteClick = () => {
                    onDeleteQRCodeClick(id);
                  };

                  const onOpenQRCodeClick = () => {
                    onOpenQRCode(id, timezone);
                  };

                  return (
                    <Table.Row
                      key={id}
                      disabled={isExpired}
                      onMouseEnter={onHover}
                      onMouseLeave={onLeave}
                    >
                      <Table.Cell>
                        <StyledCell>{id}</StyledCell>
                      </Table.Cell>

                      {!modalView && (
                        <Table.Cell>
                          <ProductTableIcon iconType={productTypeId} />
                          {deviceId}
                        </Table.Cell>
                      )}
                      <Table.Cell minWidth>
                        <StyledCell>
                          Start time:
                          <br />
                          End time:
                        </StyledCell>
                        <div>
                          {formatOperationTokenDates(startTime)}
                          <br />
                          {formatOperationTokenDates(endTime)}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          disabled={isExpired}
                          size={ButtonSize.SMALL}
                          onClick={onOpenQRCodeClick}
                        >
                          QR CODE
                        </Button>
                      </Table.Cell>
                      <Table.Cell right>
                        {showDeleteIcon[id] && (
                          <StyledBlockIcon
                            className={ActionIcon.DELETE}
                            onClick={onIconClick}
                          />
                        )}
                        {showConfirmationButtons[id] && (
                          <ButtonsContainer table>
                            <Button
                              onClick={onDeleteClick}
                              size={ButtonSize.SMALL}
                              style={{ marginRight: '5px' }}
                              primary
                            >
                              Delete
                            </Button>
                            <Button
                              size={ButtonSize.SMALL}
                              onClick={onCancelClick}
                            >
                              Cancel
                            </Button>
                          </ButtonsContainer>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell merged={5}>
                    <StyledNoData>No shared QR Codes.</StyledNoData>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Card>
        {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
          <Pagination meta={meta} getData={getQRCodesData} />
        )}
      </Content>
    </Main>
  );
}
