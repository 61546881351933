import React from 'react';
import InquiryResponse from './InquiryResponse';
import SendInquiryForm from './SendInquiryForm';

interface SendInquiryModalProps {
  closeParentModal: () => void;
  onTryAgainClick: () => void;
  inquiryResponseInfo: { success: boolean; message: string } | undefined;
}

export default function SendInquiryModal(props: SendInquiryModalProps) {
  const { closeParentModal, onTryAgainClick, inquiryResponseInfo } = props;

  return (
    <>
      {inquiryResponseInfo ? (
        <InquiryResponse
          success={inquiryResponseInfo.success}
          message={inquiryResponseInfo.message}
          onTryAgainClick={onTryAgainClick}
          closeParentModal={closeParentModal}
        />
      ) : (
        <SendInquiryForm closeParentModal={closeParentModal} />
      )}
    </>
  );
}
