import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ButtonsContainer from 'src/components/Shared/ButtonsContainer';
import Content from 'src/components/Shared/Content';
import SelectFieldWithoutFormik from 'src/components/Shared/SelectField/SelectFieldWithoutFormik';
import StyledBlockIcon from 'src/components/Shared/StyledBlockIcon';
import StyledNoData from 'src/components/Shared/StyledNoData';
import Title from 'src/components/Shared/Title';
import InvitationKeysStatuses from 'src/constants/KeyManagement/InvitationKeysStatuses';
import InvitationKeysStatusValues from 'src/constants/KeyManagement/InvitationKeysStatusValues';
import ActionIcon from 'src/constants/Shared/ActionIcon';
import PaginationSize from 'src/constants/Shared/DataSize';
import InitialMetaData from 'src/constants/Shared/InitialMetaData';
import type SharedListFilterValues from 'src/models/KeySharing/SharedListFilterValues';
import formatOperationTokenDates from 'src/utils/formatOperationTokenDates';
import { getInvitationKeyStatus } from 'src/utils/getKeyStatus';
import styled from 'styled-components';
import * as yup from 'yup';
import ButtonSize from '../../../constants/Shared/ButtonSize';
import Color from '../../../constants/Shared/Color';
import InvitationStates from '../../../constants/Shared/InvitationStates';
import PaginationItemDisplay from '../../../constants/Shared/PaginationItemDisplay';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import type Dropdown from '../../../models/KeySharing/Dropdown';
import type InvitationKey from '../../../models/KeySharing/InvitationKey/InvitationKey';
import type Meta from '../../../models/Shared/Meta';
import type Option from '../../../models/Shared/Option';
import useInvitationKeysService from '../../../services/KeyManagement/useInvitationKeysService';
import Button from '../../Shared/Button';
import Card from '../../Shared/Card';
import CheckBox from '../../Shared/CheckBox';
import Control from '../../Shared/Control';
import DropdownFilter from '../../Shared/DropdownFilter';
import Form from '../../Shared/Form';
import Main from '../../Shared/Main';
import { useGlobalConfirmationModal } from '../../Shared/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import Pagination from '../../Shared/Pagination';
import SearchFilter from '../../Shared/SearchFilter';
import StyledCell from '../../Shared/StyledCell';
import Table from '../../Shared/Table';
import ProductTableIcon from '../ProductTableIcon';
import InfoMessages from './InfoMessages';

const initialValues: SharedListFilterValues = {
  receiverIdentifier: '',
  dateFrom: null,
  dateTo: null,
};

const optiondata: Option[] = [
  { key: InvitationKeysStatusValues.ALL, label: 'All' },
  { key: InvitationKeysStatusValues.PENDING, label: 'Pending' },
  { key: InvitationKeysStatusValues.ACTIVATED, label: 'Activated' },
  { key: InvitationKeysStatusValues.BLOCKED, label: 'Blocked' },
  { key: InvitationKeysStatusValues.EXPIRED, label: 'Expired' },
  { key: InvitationKeysStatusValues.INVALID, label: 'Invalid' },
  { key: InvitationKeysStatusValues.DELETED, label: 'Deleted' },
];

const initialDropdownValues: Dropdown = {
  filter: {
    key: InvitationKeysStatusValues.ALL,
    label: 'All',
  },
};

const validationSchema = yup.object().shape({
  receiverIdentifier: yup
    .string()
    .min(3, 'Email should be at least 3 characters long'),
  dateFrom: yup.date().nullable().notRequired(),
  dateTo: yup
    .date()
    .nullable()
    .notRequired()
    .when('dateFrom', {
      is: (dateFrom: Date | null) => dateFrom !== null,
      then: yup
        .date()
        .nullable()
        .notRequired()
        .min(yup.ref('dateFrom'), "End Date can't be before Start Date"),
    }),
});

interface InvitationKeysTableProps {
  modalView?: boolean;
  productId?: number;
}

export default function InvitationKeysTable(props: InvitationKeysTableProps) {
  const { modalView, productId } = props;
  const {
    listAllInvitationKeys,
    blockInvitationKey,
    blockInvitationKeyById,
    resendInvitationKeys,
  } = useInvitationKeysService();
  const isMounted = useIsMounted();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState<{
    [key: number]: boolean;
  }>({});
  const [showConfirmationButtons, setShowConfirmationButtons] = useState<{
    [key: number]: boolean;
  }>({});
  const [filter, setFilter] = useState<Option | undefined | null>(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState<InvitationKey[]>([]);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);

  const { params } = useRouteMatch<{ lotId: string }>();
  const { lotId } = params;

  const [filterValues, setFilterValues] = useState<SharedListFilterValues>({
    receiverIdentifier: '',
    dateFrom: undefined,
    dateTo: undefined,
  });

  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const [totalSelectableKeys, setTotalSelectableKeys] = useState<
    InvitationKey[]
  >([]);

  const options: Option[] = optiondata.map(({ key, label }) => ({
    key,
    label,
  }));

  const getInvitationKeysData = useCallback(
    async (page: number) => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { dateFrom, dateTo, receiverIdentifier } = filterValues;

        const { data, meta } = await listAllInvitationKeys({
          page,
          size: PaginationSize.STANDARD,
          lotId: lotId ? parseInt(lotId) : undefined,
          productId,
          receiverIdentifier,
          dateFrom: dateFrom?.toISOString(),
          dateTo: dateTo?.toISOString(),
          status: getInvitationKeyStatus(filter?.key),
        });
        if (isMounted()) {
          setData(data);
          setSelectedKeys([]);
          setIsDataLoading(false);

          const allSelcetableKeys = data.filter(
            (key: InvitationKey) =>
              (key.status === InvitationKeysStatuses.PENDING &&
                new Date(key.endTime) >= new Date()) ||
              key.status === InvitationKeysStatuses.INVALID
          );
          setTotalSelectableKeys(allSelcetableKeys);

          if (meta !== undefined) {
            setMeta(meta);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [listAllInvitationKeys, isMounted, lotId, filterValues, filter, productId]
  );

  useEffect(() => {
    getInvitationKeysData(1);
  }, [getInvitationKeysData]);

  const onSubmit = useCallback(
    async (values: SharedListFilterValues) => {
      const { receiverIdentifier, dateFrom, dateTo } = values;

      if (isMounted()) {
        setFilterValues((oldVal) => {
          const {
            dateFrom: oldDateFrom,
            receiverIdentifier: oldreceiverIdentifier,
            dateTo: oldDateTo,
          } = oldVal;
          if (
            oldDateFrom === dateFrom &&
            oldreceiverIdentifier === receiverIdentifier &&
            oldDateTo === dateTo
          ) {
            return oldVal;
          }
          return {
            receiverIdentifier,
            dateFrom,
            dateTo,
          };
        });
      }
    },
    [isMounted]
  );

  const onResetAllFilters = useCallback(() => {
    if (isMounted()) {
      setFilterValues((oldVal) => {
        const {
          dateFrom: oldDateFrom,
          receiverIdentifier: oldreceiverIdentifier,
          dateTo: oldDateTo,
        } = oldVal;
        if (!oldDateFrom && oldreceiverIdentifier === '' && !oldDateTo) {
          return oldVal;
        }
        return {
          receiverIdentifier: '',
          dateFrom: undefined,
          dateTo: undefined,
        };
      });
    }
  }, [isMounted]);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: successMessage,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onBlockSubmit = useCallback(async () => {
    try {
      if (selectedKeys.length === 0) return;

      const response = await blockInvitationKey(selectedKeys);

      const successfullyBlocked = response
        .filter((key) => key.success)
        .map((key) => key.id);

      if (isMounted()) {
        setData((oldData) =>
          oldData.map((oldSentKey) => {
            if (successfullyBlocked.includes(oldSentKey.id)) {
              return {
                ...oldSentKey,
                status: InvitationKeysStatuses.BLOCKED,
              };
            }
            return oldSentKey;
          })
        );
        successfullyBlocked.length === selectedKeys.length
          ? setSuccessMessage('All keys are blocked successfully!')
          : setSuccessMessage(
              'Sorry for the inconvenience! Something went wrong, please try again.'
            );
        setTotalSelectableKeys((oldSelectableKeys) =>
          oldSelectableKeys.filter(
            (oldKey) => !selectedKeys.includes(oldKey.id)
          )
        );
        setSelectedKeys([]);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    isMounted,
    selectedKeys,
    blockInvitationKey,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
  ]);

  const onResendSubmit = useCallback(async () => {
    try {
      if (selectedKeys.length === 0) return;

      const response = await resendInvitationKeys({ ids: selectedKeys });

      const resendFailed = response.findIndex((key) => !key.success);

      if (isMounted()) {
        if (resendFailed === -1) {
          setData((oldData) =>
            oldData.map((oldSentKey) => {
              if (selectedKeys.includes(oldSentKey.id)) {
                return {
                  ...oldSentKey,
                  status: InvitationKeysStatuses.PENDING,
                };
              }
              return oldSentKey;
            })
          );
          setSuccessMessage('All keys are resend successfully!');
        } else {
          getInvitationKeysData(1);
          setSuccessMessage(
            'Sorry for the inconvenience! Something went wrong, please try again.'
          );
        }

        setSelectedKeys([]);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    isMounted,
    selectedKeys,
    resendInvitationKeys,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
    getInvitationKeysData,
  ]);

  const updateSelectableKeys = useCallback((id: number) => {
    setSelectedKeys((selectedKeys) => {
      if (selectedKeys.includes(id)) {
        return selectedKeys.filter((selectedKey) => selectedKey !== id);
      }
      return [...selectedKeys, id];
    });
  }, []);

  const onSelectAllSelectableKeys = useCallback(() => {
    if (selectedKeys.length === totalSelectableKeys.length) {
      setSelectedKeys([]);
      return;
    }

    const selectableKeys = data.filter(
      (selectableKey) =>
        (selectableKey.status === InvitationKeysStatuses.PENDING &&
          new Date(selectableKey.endTime) >= new Date()) ||
        selectableKey.status === InvitationKeysStatuses.INVALID
    );

    if (selectableKeys.length === 0) {
      return;
    }

    const selectableKeyIds = selectableKeys.map(
      (selectableKey) => selectableKey.id
    );

    setSelectedKeys(selectableKeyIds);
  }, [data, selectedKeys, totalSelectableKeys]);

  const { openGlobalConfirmationModal: openBlockKeyModal } =
    useGlobalConfirmationModal({
      action: onBlockSubmit,
      message:
        'Are you sure you want to block all selected Pending keys? This action is permanent and can not be undone.',
    });

  const { openGlobalConfirmationModal: openResendKeyModal } =
    useGlobalConfirmationModal({
      action: onResendSubmit,
      message: 'Are you sure you want to resend all selected Pending keys?',
    });

  const onDeleteIconClick = useCallback(
    (id: number) => {
      if (showDeleteIcon[id]) {
        setShowDeleteIcon({ [id]: false });
        setShowConfirmationButtons({ [id]: true });
      }
    },
    [showDeleteIcon]
  );

  const onKeyBlock = useCallback(
    async (id: number) => {
      try {
        await blockInvitationKeyById(id);

        if (isMounted()) {
          setData((oldData) => {
            const index = oldData.findIndex((data) => data.id === id);
            if (index !== -1) {
              oldData[index].status = InvitationKeysStatuses.BLOCKED;
            }
            return oldData;
          });
          setTotalSelectableKeys((oldSelectableKeys) =>
            oldSelectableKeys.filter((oldKey) => oldKey.id !== id)
          );
          setShowConfirmationButtons({ [id]: false });
          setSuccessMessage('Key is blocked successfully!');
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      blockInvitationKeyById,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  const onSelectDropdownValue = useCallback((value: Option | undefined) => {
    setFilter(value);
  }, []);

  return (
    <Main modalView={modalView}>
      <Content widthSize='27%' modalView={modalView}>
        {!modalView && <Title>Pending Keys</Title>}
        <Form
          name='Filter'
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <SearchFilter
            maxBookingDateFlag
            emailFlag
            modalView={modalView}
            onResetAllFilters={onResetAllFilters}
          />
        </Form>
      </Content>
      <Content widthSize={modalView ? '100%' : '70%'} addTopMargin={!modalView}>
        <Card sticky modalView={modalView}>
          <Control left>
            <CheckBox
              checked={
                totalSelectableKeys.length > 0 &&
                totalSelectableKeys.length === selectedKeys.length
              }
              onClick={onSelectAllSelectableKeys}
              disabled={totalSelectableKeys.length === 0}
              readonly
              title='Select All'
            />
            <DropdownFilter>
              <SelectFieldWithoutFormik
                label=''
                placeholder='All'
                name='filter'
                initialValue={initialDropdownValues.filter}
                options={options}
                hideInput
                onChange={onSelectDropdownValue}
              />
            </DropdownFilter>
            {selectedKeys.length > 0 ? (
              <>
                <Button
                  size={ButtonSize.MIDDLE}
                  style={{ marginRight: '20px' }}
                  onClick={openBlockKeyModal}
                >
                  Block
                </Button>
                <Button size={ButtonSize.MIDDLE} onClick={openResendKeyModal}>
                  Resend
                </Button>
              </>
            ) : null}
          </Control>
        </Card>
        <Card modalView={modalView}>
          <Table isLoading={isDataLoading}>
            <Table.Head>
              <Table.Row>
                <Table.Header />
                <Table.Header>EMAIL</Table.Header>
                {!modalView && <Table.Header>PRODUCT</Table.Header>}
                <Table.Header>SHARED KEYS</Table.Header>
                <Table.Header />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {data.length > 0 ? (
                data.map((keyData) => {
                  const {
                    id,
                    receiverIdentifier,
                    product,
                    startTime,
                    endTime,
                    status,
                    activatedAt,
                  } = keyData;
                  const { name, productTypeId } = product;

                  const selected = selectedKeys.includes(id);

                  const selectId = () => {
                    updateSelectableKeys(id);
                  };

                  const blockableKey =
                    (status === InvitationKeysStatuses.PENDING &&
                      new Date(endTime) >= new Date()) ||
                    status === InvitationKeysStatuses.INVALID;

                  const isExpired =
                    new Date(endTime) < new Date() &&
                    status === InvitationKeysStatuses.PENDING;

                  const onHover = () => {
                    if (blockableKey && showConfirmationButtons[id] !== true) {
                      setShowDeleteIcon({ [id]: true });
                    }
                  };
                  const onLeave = () => {
                    setShowDeleteIcon({ [id]: false });
                  };

                  const onIconClick = () => {
                    onDeleteIconClick(id);
                  };

                  const onBlockClick = () => {
                    onKeyBlock(id);
                  };

                  const onCancelClick = () => {
                    setShowConfirmationButtons({ [id]: false });
                  };

                  return (
                    <Table.Row
                      key={id}
                      disabled={
                        (status !== InvitationKeysStatuses.PENDING &&
                          status !== InvitationKeysStatuses.INVALID) ||
                        isExpired
                      }
                      onMouseEnter={onHover}
                      onMouseLeave={onLeave}
                    >
                      <Table.Cell>
                        <CheckBox
                          checked={selected}
                          onClick={selectId}
                          disabled={
                            (status !== InvitationKeysStatuses.PENDING &&
                              status !== InvitationKeysStatuses.INVALID) ||
                            isExpired
                          }
                          readonly
                          title='Select'
                        />
                      </Table.Cell>
                      <Table.Cell title={receiverIdentifier} short>
                        {receiverIdentifier}
                      </Table.Cell>
                      {!modalView && (
                        <Table.Cell>
                          <ProductTableIcon iconType={productTypeId} />
                          {name}
                        </Table.Cell>
                      )}
                      <Table.Cell minWidth>
                        <StyledCell>
                          Start time:
                          <br />
                          End time:
                        </StyledCell>
                        <div>
                          {formatOperationTokenDates(startTime)}
                          <br />
                          {formatOperationTokenDates(endTime)}
                        </div>
                      </Table.Cell>
                      <Table.Cell
                        invalid={status === InvitationKeysStatuses.INVALID}
                        disabled={status === InvitationKeysStatuses.PENDING}
                      >
                        <StyledDiv>
                          {status === InvitationKeysStatuses.PENDING &&
                          !isExpired ? (
                            InvitationStates.PENDING
                          ) : (
                            <InfoMessages
                              status={status}
                              type='Key'
                              isExpired={isExpired}
                            />
                          )}
                          {status === InvitationKeysStatuses.ACTIVATED && (
                            <ActivatedAtText>
                              {formatOperationTokenDates(activatedAt)}
                            </ActivatedAtText>
                          )}
                        </StyledDiv>
                      </Table.Cell>
                      <Table.Cell right>
                        {showDeleteIcon[id] && (
                          <StyledBlockIcon
                            className={ActionIcon.DELETE}
                            onClick={onIconClick}
                          />
                        )}
                        {showConfirmationButtons[id] && (
                          <ButtonsContainer table>
                            <Button
                              size={ButtonSize.SMALL}
                              style={{ marginRight: '5px' }}
                              onClick={onBlockClick}
                              primary
                            >
                              Block
                            </Button>
                            <Button
                              size={ButtonSize.SMALL}
                              onClick={onCancelClick}
                            >
                              Cancel
                            </Button>
                          </ButtonsContainer>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell merged={5}>
                    <StyledNoData>No shared digital keys.</StyledNoData>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Card>
        {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
          <Pagination meta={meta} getData={getInvitationKeysData} />
        )}
      </Content>
    </Main>
  );
}

const StyledDiv = styled.div`
  display: block;
  width: 100%;
`;

const ActivatedAtText = styled.div`
  color: ${Color.TEXT_LIGHT};
  font-size: 10px;
`;
