import React from 'react';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import styled from 'styled-components';
import Button from '../Shared/Button';
import ExitButton from '../Shared/Buttons/ExitButton';
import Description from '../Shared/Description';
import Line from '../Shared/Line';
import Section from '../Shared/Section';
import StyledModalIcon from '../Shared/StyledModalIcon';

interface InquiryResponseProps {
  closeParentModal: () => void;
  onTryAgainClick?: () => void;
  success: boolean;
  message: string;
}

export default function InquiryResponse(props: InquiryResponseProps) {
  const { closeParentModal, onTryAgainClick, success, message } = props;

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Section>
        <StyledModalIcon
          className={success ? 'pa-check-circle' : 'pa-x-circle'}
          size='80px'
          data-testid={success ? 'pa-check-circle' : 'pa-x-circle'}
        />
        <Description inquiryResponseView>{message} </Description>
        <StyledDiv>
          {!success && (
            <Button
              type='button'
              size={ButtonSize.MEDIUM}
              onClick={onTryAgainClick}
            >
              Try again
            </Button>
          )}
        </StyledDiv>
        <Line addMargin />
      </Section>
    </>
  );
}

const StyledDiv = styled.div`
  display: flex;
  align-self: center;
  width: 232px;
`;
