import { useFormikContext } from 'formik';
import React from 'react';
import ButtonSize from '../../constants/Shared/ButtonSize';
import useWizardContext from '../../hooks/Shared/useWizardContext';
import Button from '../Shared/Button';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import Section from '../Shared/Section';
import TextField from '../Shared/TextField';
import Title from '../Shared/Title';

export default function UserStep() {
  const { back } = useWizardContext();
  const { isSubmitting } = useFormikContext();

  return (
    <>
      <Section>
        <Title bold>Invite User</Title>
        <TextField
          label='Email'
          placeholder='Email'
          type='text'
          name='loginEmail'
          stacked
        />
        <TextField
          label='Name'
          placeholder='Name'
          type='text'
          name='loginName'
          stacked
        />
      </Section>
      <Line />
      <Control>
        <Button
          onClick={back}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Back
        </Button>
        <Button
          size={ButtonSize.LARGE}
          type='submit'
          primary
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Finish
        </Button>
      </Control>
    </>
  );
}
