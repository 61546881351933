import React from 'react';
import type AccountProductLicenses from 'src/models/License/AccountProductLicenses';
import type License from 'src/models/License/License';
import nounCount from 'src/utils/nounCount';
import styled from 'styled-components';

interface GetPurchaseDescriptionProps {
  productValue: number;
  comma: boolean;
  description: string;
}

interface GetDescriptionProps {
  productValue: number;
  description: string;
  comma: boolean;
}

export function GetLicenseDescription(license: License | undefined) {
  if (!license) return '';
  const { barriersCount, lprCamerasCount, brainModulesCount } = license;

  const products = [
    {
      product: barriersCount,
      desciption: 'Barriers',
    },
    {
      product: lprCamerasCount,
      desciption: 'Cameras',
    },
    {
      product: brainModulesCount,
      desciption: 'Gates / Chains / Bollards',
    },
  ];

  const filteredProducts = products.filter((product) => product.product > 0);

  return filteredProducts.map((productValue, index) => {
    const { product, desciption } = productValue;
    return (
      <span key={index}>
        <GetDescription
          productValue={product}
          description={desciption}
          comma={index + 1 !== filteredProducts.length}
        />
      </span>
    );
  });
}

export function GetLicensePurchaseDescription(
  licensePurchase: AccountProductLicenses | undefined
) {
  if (!licensePurchase) return '';
  const {
    bollards,
    brains,
    chains,
    barriers,
    lprCameras,
    parkingPlaces,
    gates,
  } = licensePurchase;

  const products = [
    {
      productValue: barriers,
      desciption: 'Barriers',
    },
    {
      productValue: bollards,
      desciption: 'Bollards',
    },
    {
      productValue: brains,
      desciption: 'Brains',
    },
    {
      productValue: lprCameras,
      desciption: 'Cameras',
    },
    {
      productValue: chains,
      desciption: 'Chains',
    },
    {
      productValue: gates,
      desciption: 'Gates',
    },
    {
      productValue: parkingPlaces,
      desciption: 'Parking places',
    },
  ];

  const filteredProducts = products.filter(
    (product) => product.productValue > 0
  );

  return filteredProducts.map((product, index) => {
    const { productValue, desciption } = product;
    return (
      <span key={index}>
        <GetPurchaseDescription
          productValue={productValue}
          comma={index + 1 !== filteredProducts.length}
          description={nounCount(desciption, productValue)}
        />
      </span>
    );
  });
}

export function GetPurchaseDescription(props: GetPurchaseDescriptionProps) {
  const { productValue, comma, description } = props;
  return (
    <span>
      <StyledSpan>{productValue + ' '}</StyledSpan>
      {description}
      {comma && ',  '}
    </span>
  );
}

export function GetDescription(props: GetDescriptionProps) {
  const { productValue, description, comma } = props;
  return (
    <>
      {`${productValue} ${description}`}
      {comma && ', '}
    </>
  );
}

const StyledSpan = styled.span`
  font-weight: 900;
`;
