import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import type { Socket } from 'socket.io-client';
import InProgress from 'src/components/Shared/InProgress';
import SocketEvents from 'src/constants/Shared/SocketEvents';
import SocketPath from 'src/constants/Shared/SocketPath';
import SocketTimer from 'src/constants/Shared/SocketTimer';
import useSocket from 'src/hooks/Shared/useSocket';
import toLotChangeEvent from 'src/mappers/Parking/Lot/toLotChangeEvent';
import type LotChangeEventPayload from 'src/models/Parking/Lot/SocketEvents/LotChangeEvent/LotChangeEventPayload';
import PermanentPositions from 'src/models/Parking/PermanentPosition';
import SharedProductList from 'src/screens/Parking/SharedProductList';
import * as yup from 'yup';
import ProductOperations from '../../../constants/Parking/ProductOperations';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import type Gate from '../../../models/Parking/Gate/Gate';
import type GateFormValues from '../../../models/Parking/Gate/GateFormValues';
import useGateService from '../../../services/Parking/useGateService';
import useProductService from '../../../services/Parking/useProductService';
import { isNotString } from '../../../utils/checks';
import ExitButton from '../../Shared/Buttons/ExitButton';
import Form from '../../Shared/Form';
import { useGlobalConfirmationModal } from '../../Shared/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import Tabs from '../../Shared/Tabs';
import GateSystemControlForm from '../GateSystemControlForm';
import GateUpdateForm from '../GateUpdateForm';

interface GateDetailsModalProps {
  id: number | undefined;
  productId: number | undefined;
  closeParentModal: () => void;
  onProductNameChange: (name: string, id: number, zoneId?: number) => void;
  isAnyGatewayOnline: boolean;
  hideSystemControl?: boolean;
}

const defaultFormValues: GateFormValues = {
  name: '',
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
});

export default function GateDetailsModal(props: GateDetailsModalProps) {
  const {
    id,
    productId,
    closeParentModal,
    onProductNameChange,
    isAnyGatewayOnline,
    hideSystemControl,
  } = props;
  const isMounted = useIsMounted();
  const { findOneGate, removeGate, updateGate } = useGateService();
  const { changeStateOfProduct } = useProductService();
  const [details, setDetails] = useState<Gate | undefined>();
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const socket = useSocket(SocketPath.SOCKETIO);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    const loadGateDetails = async () => {
      if (!id) {
        return;
      }
      try {
        if (isMounted()) {
          setAreDetailsLoading(true);
        }
        const data = await findOneGate(id);
        if (isMounted()) {
          setDetails(data);
          setInitialValues({
            name: data.product.name,
          });
          setAreDetailsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };
    loadGateDetails();
  }, [id, isMounted, findOneGate]);

  const onGateRemove = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      await removeGate(id);

      if (isMounted()) {
        setMessageSuccess('Gate deleted successfully!');
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    id,
    removeGate,
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onGateRemove,
    message: 'Are you sure you want to remove this gate?',
  });

  const onGateFormSubmit = useCallback(
    async (
      values: GateFormValues,
      { setErrors, resetForm }: FormikHelpers<GateFormValues>
    ) => {
      try {
        if (!id) {
          return;
        }
        await updateGate(id, values);
        if (isMounted()) {
          resetForm({});
          setMessageSuccess('Gate updated successfully!');
          openGlobalSuccessModal();
          onProductNameChange(
            values.name,
            productId || 0,
            details?.product.zoneId
          );
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      id,
      updateGate,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      onProductNameChange,
      productId,
      setMessage,
      details,
    ]
  );

  const onPermanentOpenChange = useCallback(async () => {
    try {
      if (!productId) {
        return;
      }

      if (isMounted()) {
        setIsInProgress(true);
      }

      const response = await changeStateOfProduct(productId, {
        operation:
          details?.permanentPosition === PermanentPositions.STAY_OPEN
            ? ProductOperations.CLEAR_STAY_CLOSE
            : ProductOperations.SET_STAY_OPEN,
      });

      let operationListener: Socket | undefined;

      const operationTimeout = setTimeout(() => {
        if (operationListener) {
          operationListener = socket?.off(
            SocketEvents.LOT_CHANGE,
            handleLotChange
          );
        }
        setIsInProgress(false);

        setMessage({
          code: 'Unknown',
          message: 'Operation timeout',
        });
        openGlobalFailureModal();
      }, SocketTimer.PRODUCT_OPERATION_TIMEOUT);

      if (!socket) {
        throw Error();
      }

      const handleLotChange = (payload: string) => {
        try {
          const eventPayload: LotChangeEventPayload = JSON.parse(payload);
          const event = toLotChangeEvent(eventPayload);
          const { eventId } = event;

          if (response.eventId !== eventId) return;

          clearTimeout(operationTimeout);
          operationListener = socket?.off(
            SocketEvents.LOT_CHANGE,
            handleLotChange
          );

          setDetails((oldData) => {
            if (!oldData || !event.data.gate) {
              return oldData;
            }
            return {
              ...oldData,
              permanentPosition: event.data.gate.permanentPosition,
            };
          });

          if (isMounted()) {
            setIsInProgress(false);
          }
        } catch {
          setMessage({ code: 'Unknown', message: 'JSON parse error' });
          openGlobalFailureModal();
        }
      };

      operationListener = socket?.on(SocketEvents.LOT_CHANGE, handleLotChange);
    } catch (error) {
      if (isMounted()) {
        setIsInProgress(false);
      }
      throw error;
    }
  }, [
    changeStateOfProduct,
    productId,
    isMounted,
    openGlobalFailureModal,
    setMessage,
    socket,
    details,
  ]);

  return (
    <>
      {isInProgress && <InProgress />}
      <ExitButton onClick={closeParentModal} />
      <Tabs>
        <Tabs.Panel name='details' label='Details'>
          <Form
            name='gate'
            onSubmit={onGateFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            isLoading={areDetailsLoading}
          >
            <GateUpdateForm
              details={details}
              openRemoveModal={openGlobalConfirmationModal}
              closeParentModal={closeParentModal}
              isAnyGatewayOnline={isAnyGatewayOnline}
              isLoading={areDetailsLoading}
            />
          </Form>
        </Tabs.Panel>
        {!hideSystemControl ? (
          <Tabs.Panel name='systemControl' label='System Control'>
            <GateSystemControlForm
              state={details?.permanentPosition}
              onChange={onPermanentOpenChange}
            />
          </Tabs.Panel>
        ) : (
          <></>
        )}
        <Tabs.Panel name='sharedAccess' label='Shared Access'>
          <SharedProductList modalView productId={productId || 0} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
