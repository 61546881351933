import type ProductLicensesFormValues from 'src/models/License/ProductLicenseFormValues';
import type ProductLicensesRequestValues from 'src/models/License/ProductLicensesRequestValues';

export default function toProductLicenseAddRequest(
  data: ProductLicensesFormValues
): ProductLicensesRequestValues {
  const {
    accountId,
    barrierCount,
    bollardCount,
    brainCount,
    chainCount,
    parkingPlaceCount,
    lprCameraCount,
    gateCount,
  } = data;

  return {
    account_id: accountId,
    barriers: barrierCount,
    lpr_cameras: lprCameraCount,
    brains: brainCount,
    chains: chainCount,
    gates: gateCount,
    bollards: bollardCount,
    parking_places: parkingPlaceCount,
  };
}
