import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import type ApiImgResponse from 'src/models/Shared/ApiDetectImgResponse';
import handleErrors from 'src/utils/handleErrors';
import ContentType from '../../constants/Shared/ContentType';
import Header from '../../constants/Shared/Header';
import Method from '../../constants/Shared/Method';
import type ApiResponse from '../../models/Shared/ApiResponse';
import formatErrors from '../../utils/formatErrors';
import useSessionService from './useSessionService';

interface ApiServiceOptions {
  withAuth: boolean;
  contentType?: ContentType;
  isParklio?: boolean;
  acceptImg?: boolean;
}

export interface ApiService {
  get: <ResponseType, ParamsType = undefined>(
    endpoint: string,
    params?: ParamsType
  ) => Promise<ApiResponse<ResponseType>>;

  post: <RequestType, ResponseType, ParamsType = undefined>(
    endpoint: string,
    body: RequestType,
    params?: ParamsType
  ) => Promise<ApiResponse<ResponseType>>;

  postResponseImg: <RequestType, ParamsType = undefined>(
    endpoint: string,
    body: RequestType,
    params?: ParamsType
  ) => Promise<ApiImgResponse>;

  patch: <RequestType, ResponseType>(
    endpoint: string,
    body: RequestType
  ) => Promise<ApiResponse<ResponseType>>;

  remove: <ResponseType, RequestType = undefined>(
    endpoint: string,
    body?: RequestType
  ) => Promise<ApiResponse<ResponseType>>;

  getImg: <ParamsType = undefined>(
    endpoint: string,
    params?: ParamsType
  ) => Promise<ApiImgResponse>;
}

const defaultOptions = {
  withAuth: true,
  contentType: ContentType.JSON,
  isParklio: false,
  acceptImg: false,
};

export default function useApiService(
  options: ApiServiceOptions = defaultOptions
): ApiService {
  const { getValidToken } = useSessionService();
  const {
    withAuth,
    contentType = ContentType.JSON,
    isParklio,
    acceptImg,
  } = options;
  const { push } = useHistory();
  const apiBaseUrl = useMemo(() => process.env.REACT_APP_API_URL || '', []);

  const apiVersion = useMemo(() => process.env.REACT_APP_API_VERSION || '', []);

  const apiUrl = useMemo(
    () => apiBaseUrl + apiVersion || '',
    [apiBaseUrl, apiVersion]
  );

  const generateHeaders = useCallback(async () => {
    const headers = new Headers();

    if (withAuth) {
      const token = await getValidToken();

      headers.append(Header.AUTHORIZATION, `Bearer ${token}`);
    }

    if (isParklio) {
      headers.append(Header.PARKLIO_INTERNAL, isParklio.toString());
    }

    switch (contentType) {
      case ContentType.JSON:
        headers.append(Header.CONTENT_TYPE, ContentType.JSON);
        break;
      default:
        break;
    }

    if (acceptImg) {
      headers.append(Header.ACCEPT, ContentType.IMG);
    }

    return headers;
  }, [getValidToken, withAuth, contentType, isParklio, acceptImg]);

  const readResponse = useCallback(
    async <ResponseType>(
      response: Response
    ): Promise<ApiResponse<ResponseType>> => {
      const { status } = response;
      const { success, data, message, errors, meta } = await response.json();

      if (status === 404) {
        push('/404');

        throw new Error('Not found');
      }

      if (!withAuth && status === 403) {
        throw handleErrors(errors);
      }

      if (!success && message) {
        throw message;
      }

      if (!success && errors) {
        throw formatErrors(errors);
      }

      return { data, meta };
    },
    [push, withAuth]
  );

  const readImgResponse = useCallback(
    async (response: Response): Promise<ApiImgResponse> => {
      const { status, headers } = response;
      const img = await response.blob();

      if (status === 404) {
        push('/404');

        throw new Error('Not found');
      }

      return { img, headers };
    },
    [push]
  );

  const serializeRequestBody = useCallback(
    <RequestType>(body: RequestType) => {
      switch (contentType) {
        case ContentType.JSON:
          return JSON.stringify(body);
        case ContentType.FORM_DATA: {
          const formData = new FormData();

          for (const key in body) {
            formData.set(key, body[key] as any);
          }

          return formData;
        }
        default:
          return '';
      }
    },
    [contentType]
  );

  const get = useCallback(
    async <ResponseType, ParamsType = undefined>(
      endpoint: string,
      params?: ParamsType
    ) => {
      const fetchUrl = new URL(`${apiUrl}${endpoint}`);
      const headers = await generateHeaders();

      if (params) {
        const queryString = qs.stringify(params);

        fetchUrl.search = queryString;
      }

      const fetchOptions = {
        method: Method.GET,
        headers,
      };

      const result = await fetch(fetchUrl.toString(), fetchOptions);
      const returnData = await readResponse<ResponseType>(result);
      return returnData;
    },
    [generateHeaders, readResponse, apiUrl]
  );

  const getImg = useCallback(
    async <ParamsType = undefined>(endpoint: string, params?: ParamsType) => {
      const fetchUrl = new URL(`${apiUrl}${endpoint}`);
      const headers = await generateHeaders();
      if (params) {
        const queryString = qs.stringify(params);

        fetchUrl.search = queryString;
      }
      const fetchOptions = {
        method: Method.GET,
        headers,
      };
      const result = await fetch(fetchUrl.toString(), fetchOptions);

      return await readImgResponse(result);
    },
    [generateHeaders, readImgResponse, apiUrl]
  );

  const post = useCallback(
    async <RequestType, ResponseType, ParamsType = undefined>(
      endpoint: string,
      body: RequestType,
      params?: ParamsType
    ) => {
      const fetchUrl = new URL(`${apiUrl}${endpoint}`);
      const headers = await generateHeaders();

      if (params) {
        const queryString = qs.stringify(params);

        fetchUrl.search = queryString;
      }

      const fetchOptions = {
        method: Method.POST,
        body: serializeRequestBody(body),
        headers,
      };

      const result = await fetch(fetchUrl.toString(), fetchOptions);

      return await readResponse<ResponseType>(result);
    },
    [generateHeaders, readResponse, serializeRequestBody, apiUrl]
  );

  const postResponseImg = useCallback(
    async <RequestType, ParamsType = undefined>(
      endpoint: string,
      body: RequestType,
      params?: ParamsType
    ) => {
      const fetchUrl = new URL(`${apiUrl}${endpoint}`);
      const headers = await generateHeaders();

      if (params) {
        const queryString = qs.stringify(params);

        fetchUrl.search = queryString;
      }

      const fetchOptions = {
        method: Method.POST,
        body: serializeRequestBody(body),
        headers,
      };

      const result = await fetch(fetchUrl.toString(), fetchOptions);

      return await readImgResponse(result);
    },
    [generateHeaders, readImgResponse, serializeRequestBody, apiUrl]
  );

  const patch = useCallback(
    async <RequestType, ResponseType>(endpoint: string, body: RequestType) => {
      const fetchUrl = new URL(`${apiUrl}${endpoint}`);
      const headers = await generateHeaders();

      const fetchOptions = {
        method: Method.PATCH,
        body: serializeRequestBody(body),
        headers,
      };

      const result = await fetch(fetchUrl.toString(), fetchOptions);

      return await readResponse<ResponseType>(result);
    },
    [generateHeaders, readResponse, serializeRequestBody, apiUrl]
  );

  const remove = useCallback(
    async <ResponseType, RequestType = undefined>(
      endpoint: string,
      body?: RequestType
    ) => {
      const fetchUrl = new URL(`${apiUrl}${endpoint}`);
      const headers = await generateHeaders();

      const fetchOptions = {
        method: Method.DELETE,
        body: body ? serializeRequestBody(body) : undefined,
        headers,
      };

      const result = await fetch(fetchUrl.toString(), fetchOptions);

      return await readResponse<ResponseType>(result);
    },
    [generateHeaders, readResponse, apiUrl, serializeRequestBody]
  );

  return { get, post, postResponseImg, patch, remove, getImg };
}
