import type QRCode from 'src/models/KeySharing/QRCode';
import type QRCodeResponse from 'src/models/KeySharing/QRCodeResponse';

export default function toQRCode(data: QRCodeResponse): QRCode {
  const {
    token,
    id,
    start_time,
    uuid,
    end_time,
    product_id,
    created_at,
    updated_at,
    account_id,
    product_type_id,
    device_id,
  } = data;

  return {
    id,
    token,
    uuid,
    startTime: start_time,
    endTime: end_time,
    productId: product_id,
    createdAt: created_at,
    updatedAt: updated_at,
    accountId: account_id,
    productTypeId: product_type_id,
    deviceId: device_id,
  };
}
