export default function toTimeZone(timezone: number): string {
  if (Math.abs(timezone) > 12) {
    throw new Error('Timezone out of bound');
  }

  const sign = timezone >= 0 ? '+' : '-';
  const hours = Math.floor(Math.abs(timezone));
  const minutes = (Math.abs(timezone) * 60) % 60;
  return `${sign}${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}
