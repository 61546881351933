import type ProductLicensesQuery from 'src/models/License/ProductLicenseQuery';
import type ProductLicensesQueryParams from 'src/models/License/ProductLicensesQueryParams';

export default function toProductLicensesQuery(
  data: ProductLicensesQueryParams
): ProductLicensesQuery {
  const { accountId, page, size } = data;

  return {
    account_id: accountId,
    page,
    size,
  };
}
