import type QRCodeCreateRequest from 'src/models/KeySharing/QRCodeCreateRequest';

import type QRCodeSharingFormValues from 'src/models/KeySharing/QRCodes/QRCodeSharingFormValues';
import toTimeZone from '../Shared/toTimeZone';

export default function toQRCodeCreateRequest(
  productId: number,
  timezone: number,
  data: QRCodeSharingFormValues
): QRCodeCreateRequest {
  const { startTime, endTime, isUnlimited } = data;

  const responseBody: QRCodeCreateRequest = {
    product_id: productId,
    start_time: startTime ? startTime.toISOString() : '',
    end_time: isUnlimited ? null : endTime ? endTime.toISOString() : '',
    timezone: toTimeZone(timezone),
  };
  return responseBody;
}
