import { useField } from 'formik';
import React from 'react';
import Card from 'src/components/Shared/Card';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from './Button';
import ButtonsContainer from './ButtonsContainer';
import DateField from './DateField';
import TextField from './TextField';

interface SearchFilterProps {
  onResetAllFilters?: () => void;
  maxBookingDateFlag?: boolean;
  emailFlag?: boolean;
  modalView?: boolean;
  anpr?: boolean;
}

export default function SearchFilter(props: SearchFilterProps) {
  const { maxBookingDateFlag, emailFlag, modalView, anpr, onResetAllFilters } =
    props;
  const [startDate] = useField({ name: anpr ? 'startTime' : 'dateFrom' });

  return (
    <Card removeAllStyles={modalView} removeLastChildMargin={anpr}>
      {anpr ? (
        <TextField
          label='License plate'
          placeholder='Enter License Plate'
          type='text'
          stacked
          name='licensePlate'
          customMargin
        />
      ) : (
        emailFlag && (
          <TextField
            label={modalView ? '' : 'Email'}
            placeholder='Enter email'
            type='text'
            stacked={!modalView}
            name='receiverIdentifier'
            modalView={modalView}
            customMargin={!modalView}
          />
        )
      )}
      <DateField
        name={anpr ? 'startTime' : 'dateFrom'}
        label={modalView ? '' : 'Start Date'}
        placeholder='Start Date'
        maxBookingDate={maxBookingDateFlag ? undefined : new Date()}
        stacked={!modalView}
        short={modalView}
        modalView={modalView}
      />
      <DateField
        name={anpr ? 'endTime' : 'dateTo'}
        label={modalView ? '' : 'End Date'}
        placeholder='End Date'
        minBookingDate={startDate.value}
        maxBookingDate={maxBookingDateFlag ? undefined : new Date()}
        stacked={!modalView}
        short={modalView}
        modalView={modalView}
      />
      <ButtonsContainer>
        <Button
          size={modalView ? ButtonSize.MIDDLE : ButtonSize.MEDIUM}
          type='reset'
          addVerticalMargin
          onClick={onResetAllFilters}
        >
          Reset all
        </Button>
        <Button
          size={modalView ? ButtonSize.MIDDLE : ButtonSize.MEDIUM}
          type='submit'
          addVerticalMargin
          primary
        >
          Apply filters
        </Button>
      </ButtonsContainer>
    </Card>
  );
}
