import type ProductLicenseUpdateParams from 'src/models/License/ProductLicenseUpdateParams';
import type ProductLicenseUpdateValues from 'src/models/License/ProductLicenseUpdateValues';

export default function toProductLicenseUpdateParams(
  data: ProductLicenseUpdateValues
): ProductLicenseUpdateParams {
  const { active } = data;

  return {
    active,
  };
}
